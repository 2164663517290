.homePaper {
  padding: 15px;
  margin: 5px;
  width: 200px;
  text-align: center;
}
.homePaper:hover {
  /* padding: 20px; */
  color: rgb(11, 11, 246);
  cursor: pointer;
  transform: scale(1.1);
}
